// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://ed02c407501f4731a5191b624d9e87c9@o27727.ingest.sentry.io/5909246',
  // Base Sample Rate effects Errors
  sampleRate: 1.0,
  // Skip Performance Tracing
  tracesSampleRate: 0.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /solutions\.invocacdn\.com\//i,
    /bat\.bing\.com\//i,
    /cdn\.nmgassets\.com\//i,
    /shopper\.shop\.pe\//i,
    /s3-us-west-2\.amazonaws\.com\/b2bjsstore\//i,
    /analytics\.tiktok\.com\//i,
    /static\.zdassets\.com\//i,
    /cdn\.taboola\.com\//i,
    /app\.gleen\.ai\//i,
  ],
});
